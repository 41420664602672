<template>
  <div>
    <div id="shell"></div>

    <el-dialog title="提示" :visible.sync="closeDialogVisible">
      <div class="title close-prompt-title">
        🔗 链接已断开，是否要重新连接？
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="reConnect" :loading="loading">重新连接</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Terminal } from "xterm";
import "xterm/css/xterm.css";
import { FitAddon } from "xterm-addon-fit";
export default {
  name: "WebShell",
  data() {
    return {
      shellSocket: null,
      term: null,
      closeDialogVisible: false,
      loading: false
    };
  },

  computed: {
    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    token() {
      return this.$store.state.app.token;
    },

    userName() {
      return this.$store.state.app.userName;
    },

    userId() {
      return this.$store.state.app.userId;
    },

    organization() {
      return this.$store.state.app.organization;
    }
  },

  methods: {
    initTerm() {
      this.term = new Terminal({
        cursorStyle: "underline",
        cursorBlink: true
      });

      let fitAddon = new FitAddon();
      this.term.loadAddon(fitAddon);
      this.term.open(document.getElementById("shell"));
      fitAddon.fit();
    },

    createConnect() {
      this.loading = true;

      let wsProtocol = window.location.protocol === "http:" ? "ws://" : "wss://";
      let pod = this.$route.params.name;
      let container = this.$route.params.container;

      let params = `X-KubeStar-Token=${this.token}&X-KubeStar-UserID=${this.userId}&X-KubeStar-UserName=${this.userId}&X-KubeStar-Organization=${this.organization}`;
      let url = `${wsProtocol}${window.location.host}/api/v1/k8s.kubestar.io/vendors/${this.vendor}/regions/${this.region}/clusters/${this.cluster}/namespaces/${this.namespace}/pods/${pod}/containers/${container}?${params}`;

      this.shellSocket = new WebSocket(url);

      this.loading = false;

      this.shellSocket.onopen = () => {
        // this.term.clear();
        this.term.prompt();
      };

      this.shellSocket.onmessage = ev => {
        let { Data, Op } = JSON.parse(ev.data);
        if (Op === "stdout") this.term.write(Data);
      };

      this.shellSocket.onerror = () => {
        this.term.writeln(`[Connent Error]: Error`);
      };

      this.shellSocket.onclose = () => {
        this.term.writeln(`[Connent Error]: Close`);
        this.closeDialogVisible = true;
      };
    },

    initTermEventHandle() {
      this.term.prompt = () => {
        this.term.write("\r\n ");
      };

      this.term.onData(e => {
        this.shellSocket.send(JSON.stringify({ Op: "stdin", Data: e }));
      });
    },

    reConnect() {
      setTimeout(() => {
        this.createConnect();
        this.closeDialogVisible = false;
      }, 1000);
    }
  },

  mounted() {
    this.initTerm();
    this.createConnect();
    this.initTermEventHandle();
  },

  beforeDestroy() {
    if (this.shellSocket) this.shellSocket.close();
    this.shellSocket = null;
  }
};
</script>

<style lang="scss" scoped>
#shell {
  width: 100%;
  height: 100vh;

  .xterm {
    height: 100%;
  }
}

.close-prompt-title {
  font-size: 14px;
}
</style>
